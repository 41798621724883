import React, { useState} from "react"
import Cover from "../../components/cover";
import Us from "../../components/us";
import Header from "../../components/en/header";

import SEO from "../../components/seo";
import "./../../styles/test.scss";
import Services from "../../components/services";
import Contact from "../../components/contact";
import Footer from "../../components/footer";
import Customers from "./../../components/customers";

const TestPage = () => {

  let [currentPage, setCurrentPage] = useState("Home");
  let lang = "en";

  return (
    <div className = "index">

    <SEO 
      title="Inicio"
      description="Consultoría para empresas en México"
    />

    <Header checked={currentPage} />

    <Cover 
      title="¿Looking for professional solutions?"
      subtitle="We have them."
      text="We have integrative solutions for your company that will take you to the next level."
      square1={{ title:"Especialized asessory", text:"Our experts collaborating with you."}}
      square2={{ title: "Professional results", text: "Comprobed quality and results" }}
    />

    <Us lang={lang} />

    <Services lang={lang} />

    <Customers lang={lang} />

    <Contact lang={lang}/>

    <Footer lang={lang} />



  </div>
  )
}

export default TestPage;
